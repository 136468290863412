module.exports = {
  "/": {
    "name": "home",
    "success": {
      "index page loaded": [
        "INDEX_LOAD"
      ]
    }
  },
  "/:path": {
    "name": "subpath",
    "success": {
      "not found error loaded": [
        "NOT_FOUND_ERROR_LOAD"
      ]
    }
  },
  "/:path/*": {
    "name": "subpath-wildcard",
    "success": {
      "not found error loaded": [
        "NOT_FOUND_ERROR_LOAD"
      ]
    }
  }
};