import { useRouteMatch, useLocation } from 'react-router-dom';
function normalizeSearch(search) {
  return search.length === 0 || search.charAt(0) === '?' ? search : `?${search}`;
}
const useIsActive = (to, exact, experimental_matchSearchExact = false) => {
  const path = typeof to === 'object' ? to.pathname : to;

  // borrowed from https://github.com/ReactTraining/react-router/blob/2d3c68b337347abd1291ea53f9ab8cb44a8a1da8/packages/react-router-dom/modules/NavLink.js#L24
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');
  const location = useLocation();
  const match = useRouteMatch({
    path: escapedPath,
    exact
  });
  if (experimental_matchSearchExact) {
    return match !== null && typeof to === 'object' && typeof to.search === 'string' && location.search === normalizeSearch(to.search);
  } else {
    return match !== null;
  }
};
export default useIsActive;