import { useWindowDimensionsContext } from './useWindowDimensionsContext';
import { useBreakpoint } from './useBreakpoint';
import * as ScreenSizes from '../layout/constants/ScreenSizes';
const SIZES = [ScreenSizes.XS, ScreenSizes.SM, ScreenSizes.MD, ScreenSizes.LG, ScreenSizes.XL];
export function useScreenSize() {
  const {
    width
  } = useWindowDimensionsContext();
  return useBreakpoint(width);
}
export const useIsMinScreenSize = minSize => {
  const screenSize = useScreenSize();
  return SIZES.indexOf(minSize) <= SIZES.indexOf(screenSize);
};
export const useIsMaxScreenSize = maxSize => {
  const screenSize = useScreenSize();
  return SIZES.indexOf(maxSize) > SIZES.indexOf(screenSize);
};