import { useMemo } from 'react';
import { useDeviceSize } from './useDeviceSize';
import { useScreenSize } from './useScreenSize';
import * as ScreenSizes from '../layout/constants/ScreenSizes';
const sizes = [ScreenSizes.XS, ScreenSizes.SM, ScreenSizes.MD, ScreenSizes.LG, ScreenSizes.XL];
export function useResponsiveProps(props, useDevice = false) {
  const deviceSize = useDeviceSize();
  const windowSize = useScreenSize();
  const screenSize = useDevice ? deviceSize : windowSize;
  return useMemo(() => {
    return Object.keys(props).reduce((acc, _key) => {
      const key = _key;
      if (typeof props[key] !== 'object') return acc;
      let breakpoint = screenSize;
      while (!(key in acc)) {
        if (!breakpoint) {
          break;
        } else if (breakpoint in props[key]) {
          acc[key] = props[key][breakpoint];
        } else {
          breakpoint = sizes[sizes.indexOf(breakpoint) - 1];
        }
      }
      return acc;
    }, {});
  }, [screenSize, props]);
}