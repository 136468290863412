import { parse as parseParams } from 'hub-http/helpers/params';
const TRACKING_PARAMS = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_id', 'utm_content', 'hubs_signup-url', 'hubs_signup-cta'];
export function getParsedTrackingParams() {
  if (!window || !window.location) return {};
  const params = parseParams(window.location.search.slice(1));
  const trackingParams = {};
  Object.keys(params).forEach(key => {
    if (TRACKING_PARAMS.includes(key)) {
      trackingParams[key] = params[key];
    }
  });
  return trackingParams;
}