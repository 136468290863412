let _ = t => t,
  _t;
import { createGlobalStyle, css } from 'styled-components';
import { GRID_BREAKPOINT_EXTRA_LARGE, GRID_BREAKPOINT_LARGE, GRID_BREAKPOINT_MEDIUM, GRID_BREAKPOINT_SMALL } from 'HubStyleTokens/sizes';
import * as ScreenSizes from '../constants/ScreenSizes';
import { SCREEN_SIZE_ORDER } from '../constants';
const screenSizeWidths = {
  [ScreenSizes.XS]: 0,
  [ScreenSizes.SM]: parseInt(GRID_BREAKPOINT_SMALL, 10),
  [ScreenSizes.MD]: parseInt(GRID_BREAKPOINT_MEDIUM, 10),
  [ScreenSizes.LG]: parseInt(GRID_BREAKPOINT_LARGE, 10),
  [ScreenSizes.XL]: parseInt(GRID_BREAKPOINT_EXTRA_LARGE, 10)
};
const pipedScreenSizes = SCREEN_SIZE_ORDER.join('|');
export const mediaQueryClassRegex = new RegExp(`media-query__(?:${pipedScreenSizes})-(?:${pipedScreenSizes}|min|max)__hidden`, 'g');
export const getMediaQueryClass = (startSize, endSizeOrBoundary) => `media-query__${startSize}-${endSizeOrBoundary}__hidden`;
const breakpointRangeStyles = size => {
  const sizeWidth = screenSizeWidths[size];
  const sizeIndex = SCREEN_SIZE_ORDER.indexOf(size);
  return SCREEN_SIZE_ORDER.reduce((final, currentSize, currentSizeIndex) => {
    if (sizeIndex < currentSizeIndex) {
      const currentSizeWidth = screenSizeWidths[currentSize];
      const maxWidth = currentSizeWidth > 0 ? currentSizeWidth - 1 : currentSizeWidth;
      return `
        ${final}
        @media screen and (min-width: ${sizeWidth}px) and (max-width: ${maxWidth}px) {
          .${getMediaQueryClass(size, currentSize)} {
            display: none !important;
          }
        }
      `;
    }
    return final;
  }, '');
};
const breakpointStyles = size => {
  const sizeWidth = screenSizeWidths[size];
  const maxWidth = sizeWidth > 0 ? sizeWidth - 1 : sizeWidth;
  return css(["@media screen and (min-width:", "px){.", "{display:none !important;}}@media screen and (max-width:", "px){.", "{display:none !important;}}", ""], sizeWidth, getMediaQueryClass(size, 'min'), maxWidth, getMediaQueryClass(size, 'max'), breakpointRangeStyles(size));
};
const MediaQueryStyles = createGlobalStyle(_t || (_t = _`
  ${0}
  ${0}
  ${0}
  ${0}
  ${0}
`), breakpointStyles(ScreenSizes.XS), breakpointStyles(ScreenSizes.SM), breakpointStyles(ScreenSizes.MD), breakpointStyles(ScreenSizes.LG), breakpointStyles(ScreenSizes.XL));
export default MediaQueryStyles;