import { useMemo } from 'react';
import { GRID_BREAKPOINT_SMALL, GRID_BREAKPOINT_MEDIUM, GRID_BREAKPOINT_LARGE, GRID_BREAKPOINT_EXTRA_LARGE } from 'HubStyleTokens/sizes';
const GRID_BREAKPOINT_SMALL_INT = parseInt(GRID_BREAKPOINT_SMALL, 10);
const GRID_BREAKPOINT_MEDIUM_INT = parseInt(GRID_BREAKPOINT_MEDIUM, 10);
const GRID_BREAKPOINT_LARGE_INT = parseInt(GRID_BREAKPOINT_LARGE, 10);
const GRID_BREAKPOINT_EXTRA_LARGE_INT = parseInt(GRID_BREAKPOINT_EXTRA_LARGE, 10);
export function useBreakpoint(width) {
  return useMemo(() => {
    if (width < GRID_BREAKPOINT_SMALL_INT) {
      return 'xs';
    } else if (width < GRID_BREAKPOINT_MEDIUM_INT) {
      return 'sm';
    } else if (width < GRID_BREAKPOINT_LARGE_INT) {
      return 'md';
    } else if (width < GRID_BREAKPOINT_EXTRA_LARGE_INT) {
      return 'lg';
    } else {
      return 'xl';
    }
  }, [width]);
}