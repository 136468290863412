import { useEffect } from 'react';
import { getIsPrerenderUserAgent } from '../../utils/userAgent';
export default function PrerenderReady({
  ready = true
}) {
  const prerenderReady = ready && getIsPrerenderUserAgent();
  useEffect(() => {
    if (prerenderReady) {
      window.prerenderReady = true;
      return () => {
        window.prerenderReady = false;
      };
    }
    return () => {};
  }, [prerenderReady]);
  return null;
}