import { getFullUrl } from 'hubspot-url-utils';
import getLang from 'I18n/utils/getLang';
import { Flow } from 'signup-constants/Flow';
import { SignupUrlGenerator } from 'signup-ui-url-generator/SignupUrlGenerator';
export const getRootUrl = (lang, useMarketplaceRoot) => {
  const root = useMarketplaceRoot || /\/marketplace/.test(window.location.pathname) ? '/marketplace' : '';
  switch (lang) {
    case 'de':
    case 'es':
    case 'it':
    case 'fr':
    case 'ja':
    case 'nl':
    case 'pt':
    case 'zh':
    case 'no':
    case 'fi':
    case 'da':
    case 'pl':
    case 'sv':
      return `/${lang}${root}`;
    default:
      return root;
  }
};
export const getAssetInAppShortUrl = () => `${getFullUrl('app', {
  hubletOverride: 'na1'
})}/l/ecosystem/marketplace/website`;
export const getFullPublicUrl = (lang, useMarketplaceRoot) => {
  return `${getFullUrl('ecosystem', {
    hubletOverride: 'na1'
  })}${getRootUrl(lang, useMarketplaceRoot)}`;
};
const getFullMarketplaceUrl = pathname => {
  const lang = getLang();
  return `${getFullPublicUrl(lang, true)}/${pathname}`;
};
export const getTemplateMarketplaceUrl = () => getFullMarketplaceUrl('templates');
export const getAppMarketplaceUrl = () => getFullMarketplaceUrl('apps');
export const getSolutionsDirectoryUrl = () => getFullMarketplaceUrl('solutions');
export const getCmsHubPricingUrl = () => 'https://www.hubspot.com/pricing/cms';
export const getTemplateProviderSignupUrl = () => 'https://offers.hubspot.com/free-marketplace-account';
export const getAppPartnerSignupUrl = () => 'https://www.hubspot.com/partners/app';
export const getCmsFreeSignUpUrl = query => new SignupUrlGenerator(Flow.CmsFree, query).getUrl();
const SOLUTION_PARTNER_URLS = {
  en: 'https://www.hubspot.com/partners/solutions',
  de: 'https://www.hubspot.de/partners/solutions',
  es: 'https://www.hubspot.es/partners/solutions',
  fr: 'https://www.hubspot.fr/partners/solutions',
  it: 'https://www.hubspot.com/partners/solutions',
  ja: 'https://www.hubspot.jp/partners/solutions',
  nl: 'https://www.hubspot.com/partners/solutions',
  pt: 'https://br.hubspot.com/partners/solutions',
  zh: 'https://www.hubspot.com/partners/solutions',
  no: 'https://www.hubspot.com/partners/solutions',
  fi: 'https://www.hubspot.com/partners/solutions',
  da: 'https://www.hubspot.com/partners/solutions',
  pl: 'https://www.hubspot.com/partners/solutions',
  sv: 'https://www.hubspot.com/partners/solutions'
};
export const getSolutionPartnerSignupUrl = () => {
  const lang = getLang();
  return SOLUTION_PARTNER_URLS[lang] || SOLUTION_PARTNER_URLS.en;
};
export const getLocalizedPublicPath = (lang, pathname = '') => {
  return `${getRootUrl(lang)}${pathname}`;
};