module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "in_app_or_public": [
        "in-app",
        "public"
      ],
      "session_url_params": "array"
    },
    "namespace": "marketplace-unified",
    "version": "1"
  },
  "interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "category": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "search_length": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "action": "string",
      "type": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "in_app_or_public": [
        "in-app",
        "public"
      ],
      "session_url_params": "array"
    },
    "namespace": "marketplace-unified",
    "version": "1"
  }
};