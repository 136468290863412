import PropTypes from 'prop-types';
import createChainablePropType from './createChainablePropType';
let logoNames;
let propType;
export function getUniqueLogoNames() {
  if (!logoNames) {
    logoNames = process.env.NODE_ENV === 'production' ? [] :
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    Object.keys(require('ui-images/metadata').logos);
  }
  return logoNames;
}
export function getLogoNamePropType() {
  if (!propType) {
    propType = PropTypes.oneOf(getUniqueLogoNames());
  }
  return createChainablePropType(propType, 'logoName');
}