/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { createTracker } from 'usage-tracker-public';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getParsedTrackingParams } from 'marketplace-ui-common/utils/trackingParams';
import events from '../../events.yaml';
let userEmailPromise;
function maybeGetUserEmail() {
  if (!userEmailPromise) {
    const response = noAuthApiClient.get('/login-verify/echo/user');
    userEmailPromise = response.then(user => user.email);
  }
  return userEmailPromise;
}
const getSessionUrlParams = () => {
  const trackingParams = getParsedTrackingParams();
  return Object.keys(trackingParams).reduce((final, key) => {
    const value = trackingParams[key];
    final.push(`${key}=${value}`);
    return final;
  }, []);
};
export default createTracker({
  events,
  properties: {
    namespace: 'marketplace-unified',
    in_app_or_public: 'public',
    session_url_params: getSessionUrlParams(),
    email: () => maybeGetUserEmail().catch(() => null)
  },
  lastKnownEventProperties: ['screen']
});